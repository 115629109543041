.navbar-section {
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px #1db9c3;
}
.navbar-title a {
  color: #1db9c3;
  letter-spacing: 0.6px;
  text-decoration: none;
  font-size: 22px;
}
/* Existing styles remain the same */

/* Desktop Language Selector */
.desktop-language-selector {
    position: relative;
    display: none; /* Hidden on mobile */
  }
  
  @media screen and (min-width: 901px) {
    .desktop-language-selector {
      display: block;
    }
  }
  
  .language-selected {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px 8px;
  }
  
  .language-selected span {
    color: white;
    font-size: 16px;
  }
  
  .flag-icon {
    width: 24px;
    height: 16px;
    object-fit: cover;
  }
  
  .language-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    z-index: 1000;
  }
  
  .language-dropdown li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .language-dropdown li:hover {
    background-color: #f5f5f5;
  }
  
  .language-dropdown span {
    color: #333;
    font-size: 14px;
  }
  
  /* Mobile Language Selector */
  .mobile-language-selector {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
  }
  
  .language-option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s;
  }
  
  .language-option.active {
    opacity: 1;
  }
  
  .language-option span {
    color: #1db9c3;
    font-size: 16px;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 900px) {
    .desktop-language-selector {
      display: none;
    }
  }
.navbar-items {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.navbar-items > li {
  list-style-type: none;
}
.navbar-links {
  text-decoration: none;
  color: white;
  font-size: 18px;
  letter-spacing: 0.8px;
}
.navbar-links:hover {
  color: #1db9c3;
}
.logo-link {
  width: 70px;
  height: auto;
}
.logo-link img {
  width: 70px;
  height: auto;
  object-fit: cover;
}
.navbar-btn {
  padding: 14px 20px;
  color: white;
  border: 1px solid transparent;
  border-radius: 28px;
  outline: transparent;
  background-color: #1db9c3;
  font-size: 18px;

  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease;
  text-decoration: none;
}

.navbar-btn:hover {
  color: #1db9c3;
  background-color: white;
  border: 1px solid #1db9c3;
}
/* Hamburger Icon */
.mobile-nav {
  display: none;
}
.hamb-icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.hamb-icon:hover {
  color: #1db9c3;
}
/* Mobile Navbar */
.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: white;
  z-index: 20;
  justify-content: center;
  align-items: center;
  transition: left 0.5s ease-in-out;
  color: #1db9c3;
}
.mobile-navbar-close {
  position: absolute;
  top: 28px;
  right: 28px;
}
.mobile-navbar-close .hamb-icon:hover {
  color: BLACK;
}
.open-nav {
  left: 0;
}
.mobile-navbar-links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  gap: 24px;
  text-align: center;
}
.mobile-navbar-links li a {
  text-decoration: none;
  color: #1db9c3;

  font-weight: bold;
  letter-spacing: 0.8px;
  transition: color 0.3s ease-in-out;
}
.mobile-navbar-links li a:hover {
  color: #1db9c3;
}
/* Responsive */
@media screen and (max-width: 900px) {
  .navbar-btn,
  .navbar-items {
    display: none;
  }
  .mobile-nav {
    display: block;
    color: #1db9c3;
  }
}
