.doctor-section {
  padding: 32px;
  margin: 0 0 32px 0;
  text-align: center;

  color: white;
}
.dt-title-content {
  margin: 0 0 64px 0;
}
.dt-title {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.dt-title > span {
  position: relative;
}
.dt-title span::before {
  content: "";
  position: absolute;
  width: 64%;
  height: 4px;
  background-color: #1db9c3;
  border-radius: 8px;
  left: 0;
  bottom: -12px;
}
.dt-description {
  margin: 64px auto 0;
  text-align: center;
  color: white;

  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.8rem;
}
/* Doctor Cards */
.dt-cards-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.dt-card {
  width: 400px;
  height: 680px;
  gap: 32px;
  text-align: left;
}
.dt-card-img {
  width: 100%;
  height: 275px;
  padding: 16px 32px 0;
  object-fit: cover;
  background-position: center;
  background-color: #dde5f8;
  border-radius: 20px;
}
.dt-card-name {
  margin: 12px 0 0 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.7px;
  text-align: center;
}
.dt-card-title {
  margin: 6px 0 8px;
  color: rgba(255, 255, 255, 0.757);
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.7px;
}
.dt-card-stars {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.dt-card-reviews {
  color: #5a5a5a;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
/* Responsive */
@media screen and (max-width: 700px) {
  .dt-title {
    font-size: 32px;
  }
  .dt-description {
    margin: 48px 0 0;
    font-size: 18px;
  }
  .dt-card {
    height: auto;
  }
  .dt-card-title {
    font-size: 14px;
  }
  .doctor-section {
    padding: 0;
    margin: 10px;
  }
}
