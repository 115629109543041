.info-section {
  padding: 32px;
  text-align: center;
  position: relative;
  background-image: url("../../src//Assets/roulet.webp");
  background-position: center;
  background-attachment: fixed;
}
.pointer {
  cursor: pointer;
}
.text-mont {
  text-align: left;
  position: relative;
  width: 85%;
  margin: auto;
  color: white;
  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 1.6;
}

.info-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #53169079;
}
.px-30 {
  font-size: 26px;
  margin: 7px 0;
}
.px-16 {
  font-size: 16px;
}
.info-title-content {
  position: relative; /* Ensure the content stays above the overlay */
}

.none {
  text-decoration: none;
  margin: auto;
}
/* .info-title-content {
  margin: 34px 0;
} */
.info-title {
  font-size: 40px;
  font-weight: bold;
  color: white;
}
.cont-1 {
  display: none;
}
.cont-2 {
  display: block;
}

.info-description {
  margin: 20px 64px;
  text-align: center;
  color: rgb(212, 212, 212);

  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 1.6;
}

.info-description-mont {
  margin: 20px auto;
  text-align: center;
  color: white;
  font-size: 16px;
  width: 70%;
}
/* Cards */
.info-cards-content {
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}

.info-cards-content a {
  z-index: 10;
  position: relative;
}
.info-cards {
  color: black;
  color: aliceblue;
  box-shadow: 0 2px 20px #1db9c3;
  border-radius: 12px;
  display: grid;
  margin: auto;
  z-index: 10;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-between;
  width: 80vw;
}
.gradient-text {
  font-size: 34px;
  font-weight: 600;
  margin: 10px;
  background: linear-gradient(to right, #1db9c3, #541690);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 10px;
  border: 1px solid white;
  border-radius: 10px;
}
.card-content {

font-size: 26px;
}
.popular {
  position: absolute;
  background-color: orange;
  padding: 10px;
  left: 0;
  top:0;
  font-weight: 600;
  z-index: 1000;
  width: 200px;
  border-bottom-right-radius: 10px;}
.info-card-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.8px;
  margin: 10px 0;
}

.info-card-description {
  margin: 10px;
  font-size: 26px;
  letter-spacing: 0.8px;
  line-height: 1.5rem;
}
.visit-button a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.visit-button button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  background-color: #1db9c3;
  box-shadow: 0 2px 20px #1db9c3;
  color: white;
  font-size: 30px;
  padding: 15px 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
}
.visit-button button img {
  width: 40px;
  margin-left: 10px;
}

.cardId {
  position: absolute;
  background-color: #1db9c3;
  padding: 10px;
  left: 0;
  font-weight: 600;
  top:40%;
  border-bottom-right-radius: 10px;
}
.info-card-icon {
  position: relative;
  background-color: transparent;
  background-color: black;
  height: 100%;
  padding: 0;
  height: 100%;
}

.info-fa-icon {
  color: #1db9c3;
  font-size: 24px;
}

.info-card-icon img {
  width: 100%;
  padding: 0;
  height: 220px;
  /* object-fit: fill; */
}

/* Responsive */
@media screen and (max-width: 1025px) {
  .info-description {
    margin: 32px;
    font-size: 14px;
  }
  .text-mont {
    width: 100%;
  }

  .info-description-mont {
    width: 90%;
    font-size: 14px;
    margin: 15px auto;
  }
  .info-title {
    font-size: 25px;
    padding-top: 20px;
  }
  .cont-1 {
    display: block;
  }
  .cont-2 {
    display: none;
  }
  .info-title-content {
    margin: 10px 0;
  }
  .content {
    /* background-color: #15afba87; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:1rem
  }
  .info-cards {
    /* grid-template-columns: repeat(1, 1fr); */
    grid-template-columns: repeat(2, 1fr);
    min-width: 98vw;
    padding: 0;
    color: white;
    height: auto;
  
    /* background: black; */
    min-height: 230px;
  }
  .info-card-icon {
    background-color: transparent;
    height: auto;
  }
  .visit-button button {
    border: 1px solid white;
    color: white;

    background-color: #1db9c3;
  }
  

  .info-card-icon {
    background-color: transparent;
    height: 100%; /* Changed from auto to 100% */
  }

  .info-card-icon img {
    width: 100%;
    height: 100%; /* Changed from 100% to fill the container */
    padding: 15px; /* Reduced padding from 30px */
    object-fit: cover; /* Changed from cover to contain */
  }


  .visit-button button {
    font-size: 26px;
  }
 
  .content {
    padding: 5px 0;
  }
  .info-section {
    padding: 0;
    margin: -10px 0;
  }
}
@media screen and (max-width: 500px) {
 
  .info-description {
    margin: 10px 8px;
    font-size: 12px;
  }
  .info-cards {
    grid-template-columns: repeat(1, 1fr);
    margin: 10px 0;
  }
  .info-card-icon img {
    min-height: 250px; /* Increased from 200px */
    width: 100%;
    padding: 10px; /* Further reduced padding for very small screens */
    object-fit: contain; /* Changed from fill to contain to maintain aspect ratio */
  }
  .info-title-content {
    margin: 20px 0;
  }

  .info-section {
    padding: 0;
    margin: -20px 0;
  }
  .lazyload-wrapper {
    height: 100%;
  }
}
