.legal-text-content {
  padding: 12px 32px;
  text-align: center;
  color: white;
}

.legal-content-text {
  width: 70%;
  margin: 20px auto;
  color: white;
  
}
/* Title */
.legal-siteTitle a {
  text-decoration: none;
  color: #9d2553;
  font-size: 48px;
  letter-spacing: 0.8px;
}
.legal-siteTitle {
  padding: 0 0 8px 0;
  border-radius: 2px;
  background-color: white;
  text-align: center;
}

/* Info */
.legal-title {
  margin: 16px 0;
  padding: 0 0 2px 0;
  border-bottom: 2px solid grey;

  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.legal-description {
  margin: 16px 0;

  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.8rem;
}
/* Footer */
.legal-footer {
  padding: 24px 40px;
  display: block;
  color: black;

  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 1.6rem;
}
/* Responsive */
@media screen and (max-width: 600px) {
    .legal-content-text{
        margin: auto;
        width: 90%;
    }
  .legal-section {
    padding: 24px;
  }
  .legal-title {
    font-size: 24px;
  }
  .legeal-description {
    font-size: 16px;
  }
  .legal-footer {
    padding: 12px 20px;
    text-align: center;
    font-size: 16px;
  }
}
